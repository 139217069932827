




































































































































































.table-extrato-creditos {
  .table-header-description {
    width: 300px !important;
  }
}
